<template>
  <v-dialog v-model="dialog" max-width="575">
    <template v-slot:activator="{ on }">
      <v-btn color="primary" class="mb-0 mt-2" outlined v-on:click.native="on.click"><v-icon class="mr-1">mdi-plus</v-icon> Adicionar Fornecedor</v-btn>
    </template>
    <v-card class="pa-3">
      <v-card-text class="pa-0">
        <v-row>
          <v-col cols="8">
            <v-select v-model="provider" outlined hide-details :items="availableProviders" label="Fornecedor" />
          </v-col>
          <v-col cols="4">
            <v-btn :disabled="!provider" color="primary" class="mb-0 mt-2" outlined @click="addProvider"
              ><v-icon class="mr-1">mdi-plus</v-icon> Adicionar</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    currentProviders: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    dialog: false,
    provider: ''
  }),
  computed: {
    availableProviders() {
      const allProviders = [
        {
          text: 'FCM',
          value: 'fcm'
        }
      ]
      return allProviders.filter(provider => !this.currentProviders.includes(provider.value))
    }
  },
  methods: {
    addProvider() {
      this.$emit('add-provider', this.provider)
      this.dialog = false
    }
  }
}
</script>
